import { NgModule } from '@angular/core';
import { TemplateDirective } from './template.directive';

export const SHARED_DIRECTIVES = [TemplateDirective];
@NgModule({
  imports: [],
  declarations: [...SHARED_DIRECTIVES],
  exports: [...SHARED_DIRECTIVES],
})
export class SharedDirectivesModule {}
